import Layout from "../components/layout";
import Seo from "../components/seo";
import { Link } from "gatsby";
import React, { useState } from "react";
import useStations from "../utils/hooks/useStations";

const BuyWeatherStationPage = () => {
  const { station } = useStations();
  const h1Text = "Buy a Weather Station";
  
  // State to track selected vendor
  const [selectedVendor, setSelectedVendor] = useState("default");

  // Event handler for dropdown change
  const handleSelectChange = (event) => {
    setSelectedVendor(event.target.value);
  };

  return (
    <Layout station={station} h1Text={h1Text}>
      <Seo title="Buy a Weather Station" />

      <div className="container px-3 mx-auto container-article xl:pt-6 lg:px-4 xl:px-12">
        <div className="md:col-span-8">
          <p>
            NEWA models work best when they use on-site hourly weather data, so
            having your own weather station is a smart investment that
            eventually pays for itself. It collects more accurate local weather
            data that improves crop forecasts. You can also{" "}
            <Link to="/partners">
              connect with local experts in NEWA member states
            </Link>{" "}
            to make these forecasts even better, helping you make smarter
            farming decisions.
          </p>
          <p>
            NEWA collaborates with private vendors to provision weather
            stations, and we do not collect any commission or profit from the
            sales. This means you can focus on finding the best fit for your
            needs. Talk directly with vendor representatives to choose an option
            that suits your budget and management needs.
          </p>

          {/* Vendor Selection Card */}
          <div
            style={{
              backgroundColor: '#f8f9fa',
              borderRadius: '10px',
              padding: '20px',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              maxWidth: '500px',
              margin: '40px auto',
              fontFamily: 'Arial, sans-serif',
            }}
          >
            <h2>Choose a Weather Station Vendor</h2>
            <select
              id="vendorSelect"
              style={{
                width: '100%',
                padding: '10px',
                borderRadius: '5px',
                border: '1px solid #ccc',
                fontSize: '16px',
              }}
              value={selectedVendor}
              onChange={handleSelectChange}
            >
              <option value="default">Choose a vendor</option>
              <option value="hobo">HOBO Data Loggers</option>
              <option value="kestrel">KestrelMet</option>
            </select>

            {/* Default Content */}
            {selectedVendor === "default" && (
              <div style={{ marginTop: '20px' }}>
                <p>Choose a weather station vendor from the dropdown menu...</p>
              </div>
            )}

            {/* HOBO Data Loggers Content */}
            {selectedVendor === "hobo" && (
              <div style={{ marginTop: '20px' }}>
                <h3>Steve Morgan, Sr. Director HOBO Outside Sales</h3>
                <p>
                  630-965-8951 (Phone)<br/>
                  508-743-3112 (Direct)<br/>
                  <a href="mailto:steve_morgan@onsetcomp.com?subject=NEWA%Weather%Station%Inquiry">steve_morgan@onsetcomp.com</a>
                </p>
                <h3><a href="https://www.onsetcomp.com/corporate/partners/newa" target="blank">Click here to visit the HOBO Data Loggers website</a></h3>
              </div>
            )}

            {/* KestrelMet Content */}
            {selectedVendor === "kestrel" && (
              <div style={{ marginTop: '20px' }}>
                <h3>Eric Rollins, Business Development Manager</h3>
                <p>
                  207-266-8465 (Phone)<br/>
                  <a href="mailto:eric.rollins@rainwise.com?subject=NEWA%Weather%Station%Inquiry">eric.rollins@rainwise.com</a>
                </p>
                <h3><a href="https://kestrelmet.com/kestrelmet-6000-ag-weather-station" target="blank">Click here to visit the KestrelMet website</a></h3>
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default BuyWeatherStationPage;
